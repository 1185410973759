import './App.css';

function App() {
    return (
        <div class="flex flex-col justify-center content-center items-center">
            <h1 className="text-4xl font-bold">
                algopurr.io
            </h1>
            <p className="text-xl p-3">
                coming soon...
            </p>
        </div>
    );
}

export default App;
